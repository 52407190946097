.array-track-drop-target {
    position: absolute;
    z-index: 1;
    //pointer-events: none;
    display: none;
    height: 10px;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.5);
    &._drop-target {
        display: block;
    }
    &._top {
        top: -5px;
    }
    &._bottom {
        bottom: -5px;
        pointer-events: none;
    }
}