//input.inline-input-editor {
//  margin-left: -4px;
//  font-size: 16px;
//  font-family: "Roboto","Open Sans",helvetica,arial,sans-serif;
//  height: 23px;
//}
//
//span.inline-input-editor {
//  color: #88f;
//  font-size: 16px;
//  cursor: pointer;
//}

.inline-editor-span {
  color: #88f;
  font-size: 16px;
  font-family: "Roboto","Open Sans",helvetica,arial,sans-serif;
  cursor: pointer;
}

.inline-input-editor {
  margin: 0;
  &>input {
    margin-left: -5px;
    font-size: 16px;
    font-family: "Roboto","Open Sans",helvetica,arial,sans-serif;
    height: 32px;
    padding-left: 5px;
    padding-top: 5px;
  }
}

.inline-select-editor {
  display: inline-block;
  margin: 0;
  &>div.form-control {
    background: transparent;
    border: none;
    width: 300px;
    padding: 0;
    margin-left: -5px;
    &>div>div {
      font-size: 16px;
      font-family: "Roboto","Open Sans",helvetica,arial,sans-serif;
      height: 32px;
      padding-left: 2px;
      padding-top: 3px;
    }
    color: black;
  }
}

.inline-color-editor {
  & .ice-color {
    height: 25px;
    width: 25px;
    border-radius: 40%;
  }

  & .ice-color-container {
    cursor: pointer;
    border-radius: 40%;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-color: white;
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
  }

}

.inline-editor-effect-preview {
  width: 100px;
  height: 100%;
  align-self: flex-start;
  display: inline-flex;
  top: 0;
  z-index: 3;
  margin-left: 15px;
}