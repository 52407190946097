.timeline-blink-container {
    display: flex;
    height: 100%;
    width: 50px;
    justify-content: space-evenly;
    align-items: center;
}

.timeline-blink-img {
    width: 35px;
    height: 35px;
    background-color: transparent;
    border-radius: 100%;
    box-sizing: border-box;
    transition: all .3s ease-out;
    transform: scale(0);

    &._animate {
        background-color: white;
        transform: scale(1);
        &._animate-active {
            background-color: transparent;
            transform: scale(0);
        }
    }
}