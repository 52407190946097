$border: 1px solid #9a9595;;
$padding: 16px;

.bsmodal {
  .header {
    padding: $padding;
    border-bottom: $border;
  }
  .content {
    padding: $padding;
    border-bottom: $border;
  }
  .actions {
    padding: $padding;
    float: right;
  }
  .close {
    color: white;
    padding: $padding;
    &:hover {
      color: #898989;
    }
  }
}