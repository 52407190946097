.track-description {
  
  user-select: none;

  &._empty {
    color: #888;
    cursor: auto;
  }

  &._link {
    color: #888;
    cursor: auto;
  }

  &._type {
    color: #bbb;
    cursor: auto;
  }

  &._desc {
    color: #888;
    cursor: auto;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    white-space: pre;
  }

  &._disabled {
    text-decoration: line-through;
  }

}