@import "../../style/variables";

.split-top-bottom-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.split-top-bottom-container-top {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
}
.split-top-bottom-container-bottom {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
}

.split-top-bottom-container-drag {
  background-color: $splitter-color;
  flex-shrink: 0;
  flex-grow: 0;
  background-image:
    radial-gradient(circle, $splitter-dot-color 70%, rgba(0,0,0,0) 70%),
    radial-gradient(circle, $splitter-dot-color 70%, rgba(0,0,0,0) 70%),
    radial-gradient(circle, $splitter-dot-color 70%, rgba(0,0,0,0) 70%)
  ;
  background-size: $splitter-size, $splitter-size, $splitter-size;
  background-position: calc(50% - #{$splitter-size * 2}), 50%, calc(50% + #{$splitter-size * 2});
  background-repeat: no-repeat, no-repeat, no-repeat;
  cursor: ns-resize;
  height: $splitter-size;
  @media (hover: none) and (pointer: coarse) {
    box-sizing: border-box;
    height: $splitter-size-mobile;
    background-image:
            radial-gradient(circle, $splitter-dot-color 30%, rgba(0,0,0,0) 30%),
            radial-gradient(circle, $splitter-dot-color 30%, rgba(0,0,0,0) 30%),
            radial-gradient(circle, $splitter-dot-color 30%, rgba(0,0,0,0) 30%)
    ;
    background-size: $splitter-size-mobile, $splitter-size-mobile, $splitter-size-mobile;
    background-position: calc(50% - #{$splitter-size-mobile}), 50%, calc(50% + #{$splitter-size-mobile});
    border-top: 3px solid $splitter-dot-color;
    border-bottom: 3px solid $splitter-dot-color;
  }
}