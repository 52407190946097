.plix-editor {
  display: flex;
  height: 100vh;
}

$track-header-height: 20px;

.track-tree {
  width: 100%;
  margin-left: 1px;
}

.track-timeline {
  width: 100%;
  position: relative;
}

.track-header {
}

.track-header-tree {
  margin: 5px;
}

.track-header-filename {
  white-space: pre;
}

.btn.track-header-icon-button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &>i {
    font-size: 21px;
  }
  &>.badge {
    margin-left: 5px;
  }
}

.track-timeline-time-col {
  position: absolute;
  top: -10px;
  bottom: 0;
  width: 4px;
  opacity: 0.9;
  cursor: ew-resize;
  background-color: white;
  z-index: 3;
  transform: translate(-2px, 0);
  mix-blend-mode: difference;
}