@import "canvas";
@import "popup";

html, html>body {
  padding: 0;
  margin: 0;
  background-color: black;
  color: white;
  font-family: "Roboto","Open Sans",helvetica,arial,sans-serif;
  overscroll-behavior-x: none; // disable two fingers back/forward swipe
}
a {
  color: #88f;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: #aaf;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
body .btn-primary:focus {
  background-color: #007bff;
  border-color: #007bff
}

.form-control-xs {
  height: calc(1em + .375rem + 2px);
  padding: .125rem .25rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.input-file-label {
  //cursor: pointer;
  //* {
  //  pointer-events: none;
  //}
  input[type=file] {
    display: none;
    pointer-events: auto;
  }
}

.popup-content {
  z-index: 1050 !important;
}