.canvas-static-preview {
  &._container {
    display: flex;
    flex-grow: 1;
    position: relative;
  }
  & ._bg {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      background-color: #000000;
      background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
      background-size: 10px 10px;
      animation: .7s linear 0s infinite canvas-static-preview-bg-move;
      animation-play-state: paused;
    }
    &:hover::before {
      animation-play-state: running;
    }
  }
  & ._canvas {
    position: absolute;
  }
}


@keyframes canvas-static-preview-bg-move {
  from {
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
  }
  to {
    background-position: -15px -5px, -15px 0px, -10px -10px, -20px -5px;
  }
}

.canvas-static-info {
  position: absolute;
  background-color: black;
  &._info {
    color: #884;
  }
  &._error {
    color: #f44;
  }
}