.timeline-editor-drag-content {
  position: relative;
  width: 100%;
  height: 100%;
}
.timeline-editor {
  position: relative;
  height: 100%;
}

.timeline-editor-grid {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.timeline-editor-records {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.timeline-editor-dummy {
  width: 0;
  height: 100%;
  position: absolute;
  background-color: #666;
  overflow: hidden;

  &._unavailable {
    background-color: #c00;
  }

  &._foreground {
    z-index: 3;
    background-color: #cc0;
  }
}

.timeline-editor-group-bg {
  position: absolute;
  background-color: #444;
  height: 100%;
  border-radius: 4px;
}

.timeline-editor-group-fg {
  position: absolute;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
  height: 100%;
  z-index: 4;
  overflow: hidden;

  .timeline-editor-group-fg-content {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: grab;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &._drag {
    background-color: transparent;
    border: 2px dashed #fff;

    &._move {
      //background-color: #222;
    }
  }
}
.timeline-editor-grouping {
  &._drag {
    &._move {
      ._captured {
        display: none;
      }
    }
  }
}