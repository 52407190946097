.timeline-record {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    &._selected {
        overflow: visible;
        .timeline-record-name {
            outline: solid 2px #888844;
            z-index: 1;
        }
    }

    &._drag {
        .timeline-record-name {
            border-style: dashed;
            border-color: rgba(255, 255, 255, 0.7);
        }
    }

    &._copy {
        .timeline-record-name {
            border-style: dashed;
        }
    }

    &._move {
        .timeline-record-name {
            background-color: transparent !important;
            color: transparent;
        }
    }
}

.timeline-record-name {
    font-size: 10pt;
    background-color: #888;
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 5px;
    bottom: 5px;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 1px 0 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: grab;
    &._disabled {
        border-color: rgba(255, 255, 255, 0.5);
    }
}

.timeline-record-scaling {
    position: absolute;
    display: inline-block;
    top: -7px;
    bottom: -7px;
    width: 5px;
    cursor: col-resize;
    box-sizing: border-box;
    z-index: 2;
    &._left {
        left: 0;
    }
    &._right {
        right: 0;
    }
    &:hover {
        background-color: rgba(255,255,255, 0.3);
    }
}