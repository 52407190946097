@import "../../../style/variables";

.track-tree-block {
  box-sizing: border-box;
  padding-left: 5px;
  width: max-content;
  min-width: 100%;
  margin: 1px 0 1px 0;
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space:pre;

  &._selected {
    outline: solid 1px #888844;
  }

  &._default {
    border-left: 1px solid #444;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    background-color: #222;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    .track-tree-block-right-content, .track-tree-drop-icon-content {
      background-color: #222;
    }
    &::before {
      content: "";
      position: absolute;
      height: calc( 50% - 1px);
      left: -$tree-padding/2 - 1px;
      width: $tree-padding/2;
      border-bottom: 1px solid #444;
    }
    &:hover::before {
      border-bottom: 1px solid #884;
    }
  }

  &._description {
    height: 23px;
  }

  &._title {
    margin-top: 10px;
    border-left: 1px solid #444;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    background-color: #333;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &._timeline {
    height: 40px;
    border-left: 1px solid #444;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    background-color: #222;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &._drag {
    outline: 2px solid #888;
  }

  &._move {
    & > * {
      opacity: 0;
    };
    background-color: transparent;
    color: transparent;
  }

  &._link {
    outline: 2px dashed #888;
  }

  &._drop {
    .track-tree-block-right-content {
      display: none;
    }

    &._drop-replace {
      .track-tree-icon-replace {
        display: block;
      }
    }
    &._drop-add-item {
      .track-tree-icon-add-item {
        display: block;
      }
    }
    &._drop-add-array {
      .track-tree-icon-add-array {
        display: block;
      }
    }
    &._drop-insert {
      &::before {
        content: "";
        height: 20px;
        width: 100%;
        left: 0;
        right: 0;
        background-color: rgba(255,255,255,0.5);
        pointer-events: none;
        position: absolute;
        z-index: 99;
      }
      &._drop-insert-top {
        &::before {
          top: -10px;
        }
      }
      &._drop-insert-bottom {
        &::before {
          bottom: -10px;
        }
      }
    }

  }

  .track-tree-block-space {
    flex-grow: 1;
  }
  .track-tree-block-right {
    position: sticky;
    right: 5px;
    display: inline-flex;
  }
  .track-tree-block-right-icon {
    position: absolute;
    pointer-events: none;
  }
  .track-tree-drop-icon-content {
    color: #88f;
    pointer-events: none;
    .track-tree-icon {
      display: none;
    }
  }

  .track-tree-icon {
    width: 20px;
    margin: 0 0 0 5px;
  }

  .track-tree-block-right-content {
    display: none;
    .track-tree-icon {
      cursor: pointer;
      &._disabled {
        cursor: none;
      }
      &:hover {
        color: #88f;
      }
    }

  }
  &:hover .track-tree-block-right-content {
    display: inline-flex;
  }
}
