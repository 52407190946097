.plix-lib-block {
    height: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.plix-lib-search {
    flex-grow: 0;
    display: flex;
    flex-shrink: 0;
    padding: 3px;
    max-width: 100%;
    & > input {
        flex-grow: 1;
        flex-shrink: 1;
        width: 50px;
        &:not(:focus) {
            background-color: inherit;
            color: inherit;
        }
    }
    & > select {
        &:not(:focus) {
            background-color: inherit;
            color: inherit;
        }
    }
}

.plix-lib-result {
    padding: 3px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
}
.plix-lib-result-row {
    position: relative;
    margin: 7px 2px 0 2px;
    display: flex;
    align-items: center;
    user-select: none;
    .timeline-record-name {
        height: 28px;
        margin-left: 5px;
        &._selected {
            outline: solid 2px #888844;
            z-index: 1;
        }
        &._drag {
            border: 2px dashed #fff;
            &._move {
                background-color: transparent !important;
                color: transparent;
            }
        }
    }
}