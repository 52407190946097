.track-expander {
    width: 20px;
    //height: 100%;
    display: inline-flex;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    color: #666;
    padding: 10px;
    & > * {
        pointer-events: none;
    }
}