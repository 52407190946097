.track-timeline-block {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 40px;

  margin: 1px 0 1px 0;

  .track-timeline-block-content {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 5px;
    width: max-content;
    &._fixed {
      position: sticky;
      display: inline-flex;
      width: max-content;
      left: 0;
    }
  }

  &._default {
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    background-color: #222;
  }
  &._description {
    height: 23px;
  }
  &._title {
    margin-top: 10px;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    background-color: #333;
  }

  &._timeline {
    height: 40px;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
    border-right: 1px solid #444;
    background-color: #222;
    width: max-content;

    .track-timeline-block-content {
      padding-left: 0;
    }
  }
}