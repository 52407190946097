.canvas-preview-container {
  margin: 5px;

  .cvs-container {
    border: 2px #444 solid;
    display: inline-block;
  }

  .controls {
    margin-top: 10px;

    .controls-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .volume-controls {
      margin-left: 10px;
    }

    .rewind-controls {
      margin-left: 10px;
      margin-top: -30px;
      .label-line {
        height: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .rewind-input-container {
          display: inline-block;
          margin-left: 10px;
          width: 60px;
        }
      }
    }

    .rate-option {
      height: 38px;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      margin-top: 10px;
      .rate-selector {
        margin-left: 10px;
      }
    }
  }
}