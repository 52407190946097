@import "../../style/variables";

.split-lr {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  ::-webkit-scrollbar {
    height: 0;
    width: 5px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
.split-lr-scroll-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
}
.split-lr-cnt {
  margin-left: $splitter-size;
  @media (hover: none) and (pointer: coarse) {
    margin-left: $splitter-size-mobile;
  }
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  min-width: 200px;
}
.split-lr-timeline {
  height: 100%;
  display: flex;
  flex-grow: 1;
}
.split-lr-left {
  width: 200px;
  min-height: 100%;
  overflow: auto;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
}
.split-lr-left-content {
  display: flex;
  flex-grow: 1;
}
.split-lr-drag {
  background-color: $splitter-color;
  display: block;
  cursor: ew-resize;
  min-width: $splitter-size;
  height: 100%;
  position: absolute;
  left: 200px;
  background-image: radial-gradient(circle, $splitter-dot-color 2.5px, rgba(255,255,255,0) 2.5px), radial-gradient(circle, $splitter-dot-color 2.5px, rgba(255,255,255,0) 2.5px), radial-gradient(circle, $splitter-dot-color 2.5px, rgba(255,255,255,0) 2.5px);
  background-position: 0 #{-$splitter-size*2}, 0, 0 #{$splitter-size*2};
  @media (hover: none) and (pointer: coarse) {
    min-width: $splitter-size-mobile;
    box-sizing: border-box;
    background-image: radial-gradient(circle, $splitter-dot-color 5px, rgba(255,255,255,0) 5px), radial-gradient(circle, $splitter-dot-color 5px, rgba(255,255,255,0) 5px), radial-gradient(circle, $splitter-dot-color 5px, rgba(255,255,255,0) 5px);
    background-position: 0 #{-$splitter-size-mobile}, 0, 0 $splitter-size-mobile;
    border-left: 3px solid $splitter-dot-color;
    border-right: 3px solid $splitter-dot-color;
  }
}